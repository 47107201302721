<template>
  <v-container class="text-center">
    <v-row justify="center">
      <v-icon class="sucess_icon my-5" color="success">check_circle</v-icon>
    </v-row>
    <v-row justify="center">
      <h3>
        Congratulations. You’re bank account is connected and it's ready to get
        paid!
      </h3>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$gtm.trackEvent({
      event: 'takein_event', // Event type [default = 'interaction'] (Optional)
      category: 'account',
      action: 'account_added',
      label: 'account_added',
      method: 'account_added',
      payload: { method: 'account_added', source: 'fb2' }
    })
  }
}
</script>
<style scoped>
.sucess_icon {
  font-size: 50px;
}
</style>
